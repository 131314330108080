import React,{useState} from 'react';
import calcTimeLeft from '../utils/timer';
import '../styles/timer.css';

const Timer = () => {
    const [timeLeft, settimeLeft] = useState({});
    setInterval(() => {
        settimeLeft(calcTimeLeft());
    }, 1000);
    
    return (
        <div className='timerComponentWrapper'>
            <div className="timerWrapper">
                <div className="timerHeading">EVENT HAS ENDED</div>
                <div className="timerCountdownSection">
                    <div className="timerCountdownSectionElement">
                        <div className="timerCountdownValue">
                            <div className="sectionGrid">{0}</div>
                            <div className="sectionGrid">{0}</div>
                        </div>
                        <div className="timerCountdownUnit">
                            days
                        </div>
                    </div> 
                    <div className="timerColon">:</div>
                    <div className="timerCountdownSectionElement">
                        <div className="timerCountdownValue">
                            <div className="sectionGrid">{0}</div>
                            <div className="sectionGrid">{0}</div>
                        </div>
                        <div className="timerCountdownUnit">
                            hours
                        </div>
                    </div>
                    <div className="timerColon">:</div>
                    <div className="timerCountdownSectionElement">
                        <div className="timerCountdownValue">
                            <div className="sectionGrid">{0}</div>
                            <div className="sectionGrid">{0}</div>
                        </div>
                        <div className="timerCountdownUnit">
                            minutes
                        </div>
                    </div> 
                    <div className="timerColon">:</div>
                    <div className="timerCountdownSectionElement">
                        <div className="timerCountdownValue">
                            <div className="sectionGrid">{0}</div>
                            <div className="sectionGrid">{0}</div>
                        </div>
                        <div className="timerCountdownUnit">
                            seconds
                        </div>
                    </div> 
                </div>
            </div>
        </div>
    )
}

export default Timer