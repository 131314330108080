import React, { useState } from "react";
import brochure from "../assets/scintillations_brochure.pdf";
import { Waypoint } from "react-waypoint";
import "../styles/AboutUs.css";

const AboutUs = ({ displayRegisterNow }) => {
  const [AttendeesValue, setAttendeesValue] = useState(0);
  const [EventsValue, setEventsValue] = useState(0);
  const [SpeakersValue, setSpeakersValue] = useState(0);
  const [StartupsValue, setStartupsValue] = useState(0);
  let changeCounterValues = true;

  const valueCounter = (initialCounterVal,finalCounterVal, setStateCallback) => {
    let currCounter = initialCounterVal;
    if(initialCounterVal >= finalCounterVal) {
        return;
    }
    if(!changeCounterValues) return;
    const counter = setInterval(() => {
      currCounter++;
      setStateCallback(currCounter);
      if (currCounter == finalCounterVal) {
        clearInterval(counter);
    }
    }, 1500 / finalCounterVal);
  };

  const _handleWaypointEnter = () => {
    valueCounter(AttendeesValue,25, setAttendeesValue);
    valueCounter(EventsValue, 30, setEventsValue);
    valueCounter(SpeakersValue,20, setSpeakersValue);
    valueCounter(StartupsValue ,50, setStartupsValue);
  };

  return (
    <div>
      <div className="mx-auto max-w-5xl p-4 mt-5" id="about-us">
        <h2 className="lg:text-6xl font-bold text-center text-white sm:text-9xl sm:mb-10 mt-20">
          About Scintillations
        </h2>
        <div className="flex mt-4 justify-between lg:flex-row lg:items-start sm:flex-col sm:items-center">
          <div className="lg:w-1/2 mx-8 my-8 sm:w-3/4">
            <p className="text-white text-center lg:text-2xl font-bold mb-5 sm:text-6xl">
              What is Scintillations ?
            </p>
            <p className="lg:text-2xl text-white sm:text-3xl sm:mt-10">
              Scintillations - The E-Summit provides a unique opportunity for
              local business leaders and potential entrepreneurs to come
              together on a common platform. It intends to facilitate the
              development of a healthy ecosystem which in turn can boost
              essential factors like innovation and new business idea, financing
              and sourcing resources, marketing and promotion, managing growth
              and succession planning among others.
            </p>
          </div>
          <div className="lg:w-1/2 mx-8 my-8 sm:w-3/4">
            <p className="text-white text-center lg:text-2xl font-bold lg:mb-5 sm:mb-8 sm:text-6xl">
              Goals of Scintillation
            </p>
            <ul className="list-inside list-disc lg:text-2xl text-white text-left sm:text-3xl">
              <li className="lg:mb-3 sm:mb-8">
                To foster entrepreneurial initiatives and activities on the
                campus. E-Summit provides a platform for the "aspiring
                entrepreneurs" to have hands-on experience before they can
                finally enter the market.
              </li>
              <li className="lg:mb-3 sm:mb-8">
                It brings together the academic community, venture capitalists,
                new age entrepreneurs, and all those passionate about
                entrepreneurship to a common ground.
              </li>
              <li className="lg:mb-3 sm:mb-8">
                The focus of the summit remains to cater to the leadership
                qualities in an individual and stimulate him into unleashing and
                stimulate him into unleashing his innovation steak.
              </li>
            </ul>
          </div>
        </div>
        <div className="text-center lg:mt-4 sm:mt-6">
          <a
            href={brochure}
            className="bg-orange-500 text-white font-bold lg:px-4 lg:py-4 sm:px-8 sm:py-6 rounded-full hover:bg-orange-600 lg:text-[1rem] sm:text-4xl"
            download
          >
            Download Brochure
          </a>
        </div>
      </div>
      <div>
        <Waypoint onEnter={_handleWaypointEnter} />
        <div className="parameterValuesContainer">
          <div className="parameterValuesCircle">
            <div className="value">{AttendeesValue}K+</div>
            <div className="value-desc">Attendees</div>
          </div>
          <div className="parameterValuesCircle">
            <div className="value">{EventsValue}+</div>
            <div className="value-desc">Events</div>
          </div>
          <div className="parameterValuesCircle">
            <div className="value">{SpeakersValue}+</div>
            <div className="value-desc">Speakers</div>
          </div>
          <div className="parameterValuesCircle">
            <div className="value">{StartupsValue}+</div>
            <div className="value-desc">StartUps</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
