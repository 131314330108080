import React, {useState} from "react";
import Modal from "./Modal";
import "../styles/Session.css";
import sessionsDesc from '../utils/sessionsDesc';

const Session = () => {
  const [modalTopic, setmodalTopic] = useState("");
  const [modalDesc, setmodalDesc] = useState("");
  const [showModal, setShowModal] = useState(false);

  const displayModal = (modalTopic, modalDesc) =>{
    setmodalTopic(modalTopic);
    setmodalDesc(modalDesc);
    setShowModal(true);
  }
  return (
    <div id="events">
      {showModal && <Modal modalTopic={modalTopic} modalDesc={modalDesc} setshowModal={setShowModal}/>}
      <section id="competition">
        <div className="Competition common item-wrap">
          <div className="events-heading mt-20">Events</div>
          <div className="comp">
            <div className="comp-row">
              <a className="session-desc">
                <div className="comp-card card session-desc">
                  <div className="session-card-topic">Cabinet</div>
                  <p>
                    {sessionsDesc.cabinet.substring(0,200)}...
                  </p>

                  <button className="eve-btn hoverables" onClick={()=>displayModal("Cabinet", sessionsDesc.cabinet)}>Read More</button>
                </div>
              </a>

              <a className="cl2 session-desc">
                <div className="comp-card card">
                  <div className="session-card-topic">
                    Entrepreneurship and Innovation
                  </div>

                  <p>
                    {sessionsDesc.innovation.substring(0,200)}...
                  </p>

                  <button className="eve-btn hoverables" onClick={()=>displayModal("Business and Innovation", sessionsDesc.innovation)}>Read More</button>
                </div>
              </a>
            </div>
            <div className="comp-row" id="row-2">
              <a className="cm1">
                <div className="comp-card card">
                  <div className="session-card-topic">
                    Sustainability
                  </div>

                  <p>
                    {sessionsDesc.sustainability.substring(0,200)}...
                  </p>

                  <button className="eve-btn hoverables" onClick={()=>displayModal("Sustainability", sessionsDesc.sustainability)}>Read More</button>
                </div>
              </a>

              <div className="comp-card card head-card" id="session-heading-order">
                <div className="session-card-heading">SESSIONS</div>
              </div>

              <a className="cl1 session-desc">
                <div className="comp-card card">
                  <div className="session-card-topic">Women Empowerment</div>

                  <p>
                    {sessionsDesc.women.substring(0,200)}...
                  </p>

                  <button className="eve-btn hoverables" onClick={()=>displayModal("Women Empowerment", sessionsDesc.women)}>Read More</button>
                </div>
              </a>
            </div>
            <div className="comp-row">
              <a className="cr1 session-desc">
                <div className="comp-card card">
                  <div className="session-card-topic">G20</div>
                  <p>
                    {sessionsDesc.g20.substring(0,200)}...
                  </p>

                  <button className="eve-btn hoverables" onClick={()=>displayModal("G20", sessionsDesc.g20)}>Read More</button>
                </div>
              </a>

              <a className="cr2 session-desc">
                <div className="comp-card card">
                  <div className="session-card-topic">Finance</div>

                  <p>
                    {sessionsDesc.finance.substring(0,200)}...
                  </p>

                  <button className="eve-btn hoverables" onClick={()=>displayModal("Finance", sessionsDesc.finance)}>Read More</button>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Session;
