import React,{useState} from 'react'
import Footer from '../components/Footer'
import AudienceImg from '../assets/Audience.png';
import SignUpForm from '../components/SignUpForm';
import SignUpConformation from '../components/SignUpConformation';
import { HeroContainer} from '../styles';

const SignUpPage = () => {
        
  //States
  const [formView, setFormView] = useState(true);

  return (
    <div>
        <HeroContainer containerHeight="90vh" bgImageUrl={AudienceImg}>
        {
            formView && <SignUpForm setFormView={setFormView}/>
        }
        {
            !formView && <SignUpConformation/>
        }
        </HeroContainer>
        <Footer/>
    </div>
  )
}

export default SignUpPage;