import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import HomePage from "./pages/HomePage";
import EventsPage from "./pages/EventsPage";
import SignUpPage from "./pages/SignUpPage";
import particleOptions from "./utils/particles";
import { useCallback } from "react";
import Particles from "react-particles";
import { loadFull } from "tsparticles";

const App = () => {
    const particlesInit = useCallback(async engine => {
      console.log(engine);
      // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
      // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
      // starting from v2 you can add only the features you need reducing the bundle size
      await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async container => {
      await console.log(container);
  }, []);

  return (
    <>
        <Particles
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={particleOptions}/>
       <BrowserRouter>
         <Navbar />
         <Routes>
           <Route path="/" element={<HomePage />}></Route>
           <Route path="/home" element={<HomePage />}></Route>
           <Route path="/events" element={<EventsPage />}></Route>
           <Route path="/signup" element={<SignUpPage />}></Route>
         </Routes>
       </BrowserRouter>
    </>
  );
};

export default App;
