import React, { useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Link as LinkScroll } from 'react-scroll';
import logo from '../assets/edc_logo.png';
import '../styles/Navbar.css';

const Navbar = () => {
  // const [isNavOpen, setIsNavOpen] = useState(false);
  const [desktopNav, setDesktopNav] = useState(true);
  const [isNavBackgroundDark, setisNavBackgroundDark] = useState(false);

  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
      const position = window.pageYOffset;
      if(position>10) {
        setisNavBackgroundDark(true);
      } else {
        setisNavBackgroundDark(false);
      }
      setScrollPosition(position);
  };
  
  useEffect(() => {
      window.addEventListener('scroll', handleScroll, { passive: true });
  }, []);


  return (
    <div className={`navbar-bg flex justify-between items-center pr-20 pl-8 sm:h-40 lg:h-20 fixed-navbar ${isNavBackgroundDark===true?"setNavBackgroundDark":""}`}>
      <img src={logo} alt="Logo" className="edc-logo sm:w-59 sm:h-24 lg:w-[10rem] lg:h-[6rem] mt-4"/>
      {!desktopNav && <div className='mobile-nav'>
        {/* Space for Mobile Navbar */}
      </div>}
      {desktopNav && <nav>
        <div className="flex justify-between w-4/7 font-poppins-semibold lg:flex sm:flex hidden">
          <Link to="home" 
            smooth={true}
            duration={1000} className="nav-item">
              <span>HOME</span>
          </Link>
          <LinkScroll to="events" 
            smooth={true}
            duration={1000} className="nav-item">
              <span>EVENTS </span>
          </LinkScroll>
          <LinkScroll to="about-us"
            smooth={true}
            duration={1000} className="nav-item">
            <span>ABOUT US </span>
          </LinkScroll>
          <LinkScroll to="footer"
            smooth={true}
            duration={1000} className="nav-item">
            <span>CONTACT US</span>
          </LinkScroll>
          <Link to="/signup">
            <span className='nav-register-now'>REGISTER NOW</span>
          </Link>
        </div>
      </nav>}
    </div>
  );
};

export default Navbar;