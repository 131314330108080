import React,{useState} from 'react';
import '../styles/Competitions.css';
import Modal from './Modal';
import competitionsDesc from '../utils/competitionsDesc';

const Competitions = () => {
  const [modalTopic, setmodalTopic] = useState("");
  const [modalDesc, setmodalDesc] = useState("");
  const [showModal, setShowModal] = useState(false);

  const displayModal = (modalTopic, modalDesc) =>{
    setmodalTopic(modalTopic);
    setmodalDesc(modalDesc);
    setShowModal(true);
  }
  return (
    <div>
      {showModal && <Modal modalTopic={modalTopic} modalDesc={modalDesc} setshowModal={setShowModal}/>}
      <div className="competitions-container-heading">Competitions</div>
      <p className="comp-info">
            Expert investor or an emerging entrepreneur or a quiz master, you
            think you know it all? Put that test to claim, battle it out in the
            field for the ultimate glory.
      </p>
      <div className="competitions-container">
        <div className="competitions-card" id='cc1' >
          <div className="competitions-card-heading">Stand-up Comedy</div>
          <div className="competitions-card-desc">
            {competitionsDesc.standup.substring(0,200)}...
          </div>
          <div className="eve-btn extended-eve-btn" onClick={()=>displayModal("Stand-up Comedy", competitionsDesc.standup)}>
            READ MORE
          </div>
        </div>
        <div className="competitions-card" id='cc2'>
        <div className="competitions-card-heading">Barter Game</div>
          <div className="competitions-card-desc">
            {competitionsDesc.bartergame.substring(0,200)}...
          </div>
          <div className="eve-btn extended-eve-btn" onClick={()=>displayModal("Barter Game", competitionsDesc.bartergame)}>
            READ MORE
          </div>
        </div>
        <div className="competitions-card" id='cc3'>
        <div className="competitions-card-heading">Minutes to Millions</div>
          <div className="competitions-card-desc">
            {competitionsDesc.mtn.substring(0, 200)}...
          </div>
          <div className="eve-btn extended-eve-btn" onClick={()=>displayModal("Minutes to Millions", competitionsDesc.mtn)}>
            READ MORE
          </div>
        </div>
        <div className="competitions-card" id='cc4'>
        <div className="competitions-card-heading">Stockwars</div>
          <div className="competitions-card-desc">
            {competitionsDesc.stockwars.substring(0,200)}...
          </div>
          <div className="eve-btn extended-eve-btn" onClick={()=>displayModal("Stockwars", competitionsDesc.stockwars)}>
            READ MORE
          </div>
        </div>
        <div className="competitions-card" id='cc5'>
        <div className="competitions-card-heading">Uthal Puthal 3.0</div>
          <div className="competitions-card-desc">
            {competitionsDesc.uthalputhal.substring(0,200)}...
          </div>
          <div className="eve-btn extended-eve-btn" onClick={()=>displayModal("Uthal Puthal 3.0",competitionsDesc.uthalputhal)}>
            READ MORE
          </div>
        </div>
      </div>
    </div>
  )
}

export default Competitions
