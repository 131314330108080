import styled from 'styled-components';

// For Hero Component

const HeroContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  width: 80%;
  margin: auto;
  height: ${(props)=> props.containerHeight};
  background:transparent;
  background-position: center;
  height: 100vh;
  backface-visibility: hidden;
  z-index:999;
  @media (max-width: 1024px){
    height: 80rem;
  }
`;

const EventTitle = styled.div`
  margin: 9.5rem 0 0rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-size: 36px;
  color: white;
  text-align: center;
  width:70%;
  height: 40%;
  font-family: 'Poppins', sans-serif;
  @media (max-width: 1024px){
    margin-top:20vh;
    width: 800px;
  }
`;

const RegisterNowButton = styled.button`
  font-family: 'xirod', 
  sans-serif; 
  font-size: ${(props)=>props.fontSize}; 
  padding: 10px 20px; 
  border-radius: 5px; 
  background-color: transparent; 
  border: 2px solid #4FC8EF; 
  color: #4FC8EF; 
  cursor: pointer; 
  transition: all 0.2s ease-in;
  z-index:100;
  &:hover{
    background-color:#4FC8EF;
    color:white;
    transform: scale(1.02);
  }

  @media (max-width: 1024px){
    font-size: 35px;
    margin: 80px 20px;
  }
`;

// For ImageGrid Component

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  margin-top: 20%;
  margin-bottom: 10%;
`;

const ImageRow = styled.tr`
  &:hover {
    td {
      background-color: transparent;
    }
  }
`;

const TintedImage = styled.td`
  background-color: rgba(128, 0, 128, 0.5);
  width: 25%;
  height: 500px;
  background-size: cover;
  background-position: center;
  background-image: url(${(props) => props.src});
`;


//SignUpForm Component

const SignupHeader = styled.div`
  font-family: 'xirod', 
  sans-serif;
  color: #4FC8EF;
  font-size: 2.5rem;
  margin: 18vh 0 0 0;
  padding: 0 0 2rem 0;
  text-align: center;
`

const RegInput = styled.input`
  width: 30rem;
  height: 2.3rem;
  background-color: transparent;
  border-bottom: 3px solid #4FC8EF;
  padding: 0 1rem;
  font-weight: 500;
  margin: 0 1rem;
  font-size: 1.2rem;
  &:focus{
    outline: none;
  }
`

const RegLabel = styled.label`
  font-size: 1.4rem;
  font-weight:500;
  text-align:center
`
const FormWrapper = styled.div`
  margin: 0 auto 5vh auto;
  display: grid;
  grid-template-columns: 3fr 7fr;
  grid-row-gap: 3rem;
  color: #70dbff;
`

const SignButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-around;
`


// SignUpConfirmation Component
const SignupConfirmatonWrapper = styled.div`
  margin: 25vh 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 18rem;
`

const CheckImage = styled.img`
  width: 6rem;
  height: 6rem;
`

const RegCompleted = styled.div`
  color: #70dbff;
  font-size: 3rem;
`


export { HeroContainer, EventTitle, RegisterNowButton, Table, ImageRow, TintedImage, SignupHeader, RegInput, FormWrapper, RegLabel, SignButtons, SignupConfirmatonWrapper, CheckImage, RegCompleted};
