import React, { useState } from "react";
import {
  RegisterNowButton,
  SignupHeader,
  RegInput,
  FormWrapper,
  RegLabel,
  SignButtons,
} from "../styles";

const SignUpForm = ({ setFormView }) => {
  //States
  const [registrationDetails, setRegistrationDetails] = useState({
    Name: "",
    Email: "",
    Phone: "",
    College: "",
  });

  //Functions
  const onChange = (e) => {
    e.preventDefault();
    setRegistrationDetails({
      ...registrationDetails,
      [e.target.name]: e.target.value,
    });
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    fetch("https://sheetdb.io/api/v1/op56szozz54nh", {
      redirect: "follow",
      method: "POST",
      body: JSON.stringify(registrationDetails),
      headers: {
        "Content-Type": "application/json",
      },
    });
    setFormView(false);
  };

  const handleReset = async (e) => {
    e.preventDefault();
    setRegistrationDetails({
      Name: "",
      Email: "",
      Phone: "",
      College: "",
    });
  };

  return (
    <div>
      <SignupHeader>
        Scintillations 2k23 <br />
      </SignupHeader>
      <FormWrapper>
        <RegLabel htmlFor="signup-name-input">Name : </RegLabel>
        <RegInput
          type="text"
          name="Name"
          id="signup-name-input"
          value={registrationDetails.Name}
          onChange={onChange}
          required
        />
        <RegLabel htmlFor="signup-email-input">Email : </RegLabel>
        <RegInput
          type="text"
          name="Email"
          id="signup-email-input"
          value={registrationDetails.Email}
          onChange={onChange}
          required
        />
        <RegLabel htmlFor="signup-phone-input">Phone No. : </RegLabel>
        <RegInput
          type="text"
          name="Phone"
          id="signup-phone-input"
          value={registrationDetails.Phone}
          onChange={onChange}
          required
        />
        <RegLabel htmlFor="signup-college-input">College : </RegLabel>
        <RegInput
          type="text"
          name="College"
          id="signup-college-input"
          value={registrationDetails.College}
          onChange={onChange}
          required
        />
      </FormWrapper>
      <SignButtons>
        <span onClick={handleSubmit}>
          <RegisterNowButton fontSize="1rem">REGISTER NOW !</RegisterNowButton>
        </span>
        <RegisterNowButton onClick={handleReset} fontSize="1rem">
          RESET
        </RegisterNowButton>
      </SignButtons>
    </div>
  );
};
export default SignUpForm;
