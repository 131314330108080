import React from 'react';
import 'animate.css';
import {Link} from 'react-router-dom'
import check from '../assets/check.png';
import {RegisterNowButton,SignupConfirmatonWrapper,CheckImage,RegCompleted} from '../styles';

const SignUpConformation = () => {
  return (
    <div>
        <SignupConfirmatonWrapper>
            <CheckImage src={check} className='animate__animated animate__bounce'/>
            <RegCompleted>See You at the Event!</RegCompleted>
            <RegisterNowButton fontSize="1rem"><Link to="/">Home</Link></RegisterNowButton>
        </SignupConfirmatonWrapper>
    </div>
  )
}

export default SignUpConformation
