import React, { useState } from 'react';
import '../styles/Workshop.css';
import workshopVideo from '../assets/workshops.mp4';
import workshopDesc from '../utils/workshopsDesc';
import Modal from './Modal';
const Workshop = () => {
  const [modalTopic, setmodalTopic] = useState("");
  const [modalDesc, setmodalDesc] = useState("");
  const [showModal, setShowModal] = useState(false);

  const displayModal = (modalTopic, modalDesc) =>{
    setmodalTopic(modalTopic);
    setmodalDesc(modalDesc);
    setShowModal(true);
  }

  return (
    <div>
      {showModal && <Modal modalTopic={modalTopic} modalDesc={modalDesc} setshowModal={setShowModal}/>}
      <section>
        <div className="workshops-heading">
        <video loop={true} muted={true} autoPlay={true}>
          <source src={workshopVideo} type="video/mp4" />
        </video>
        </div>
      <div className="Eventcard">
          <div className="eve-card eve-card-ani">
            <a href="#"  className="hoverables workshop-link">
              <div className="circle card">
                <h1 className="colo workshop-heading">HOW TO START</h1>
                <h1 className='workshop-heading'>A STARTUP</h1>
              </div>
            </a>
            <div className="rec">
              <p className='workshop-desc'>
                {workshopDesc.startStartup}
              </p>
              <button className="eve-btn hoverables" onClick={()=>displayModal("How to start a StartUp", workshopDesc.startStartup)}>READ MORE</button>
            </div>
          </div>
          <div className="eve-card eve-card-ani2">
            <a href="#" target="_blank" class="hoverables workshop-link">
              <div className="circle card">
                <h1 className="colo"><span>HOW TO</span></h1>
                <p>BECOME AN ENTREPRENEUR</p>
              </div>
            </a>

            <div className="rec">
              <p className='workshop-desc'>
                {workshopDesc.becomeAnEnterpreuner}
              </p>
              <button className="eve-btn hoverables" onClick={()=>displayModal("How to become an Entrepreneur", workshopDesc.becomeAnEnterpreuner)}>READ MORE</button>
            </div>
          </div>
          <div className="eve-card eve-card-ani3">
            <a href="#"  className="hoverables workshop-link">
              <div className="circle card">
                <h1 className="colo workshop-heading">WEB</h1>
                <h1 className='workshop-heading'>3.0</h1>
              </div>
            </a>
            <div className="rec">
              <p className='workshop-desc'>
                {workshopDesc.web3}
              </p>
              <button className="eve-btn hoverables" onClick={()=>displayModal("WEB 3.0", workshopDesc.web3)}>READ MORE</button>
            </div>
          </div>
        </div>
    </section>
    </div>
  )
}

export default Workshop
