import React from 'react';
import Hero from '../components/Hero';
import ImageGrid from '../components/ImageGrid';
import Footer from '../components/Footer';
import Workshop from '../components/Workshop';
import Session from '../components/Session';
import AboutUs from '../components/AboutUs';
import Modal from '../components/Modal';
import Competitions from '../components/Competitions';
import ImgGridR1C1 from '../assets/ImgGridR1C1.png';
import ImgGridR1C2 from '../assets/ImgGridR1C2.png';
import ImgGridR1C3 from '../assets/ImgGridR1C3.png';
import ImgGridR1C4 from '../assets/ImgGridR1C4.png';
import ImgGridR2C1 from '../assets/ImgGridR2C1.png';
import ImgGridR2C2 from '../assets/ImgGridR2C2.png';
import ImgGridR2C3 from '../assets/ImgGridR2C3.png';
import ImgGridR2C4 from '../assets/ImgGridR2C4.png';
import { RegisterNowButton } from '../styles.js';
import ScintillationTextImg from '../assets/SCINTILLATIONS.png';
import { Link } from 'react-router-dom';
import AudienceImg from '../assets/Audience.png';
import '../styles.js';

const images = [ImgGridR1C1, ImgGridR1C2, ImgGridR1C3, ImgGridR1C4,ImgGridR2C1, ImgGridR2C2, ImgGridR2C3, ImgGridR2C4];

const HomePage = () => {
  return (
    <div id="home">
      <Hero eventName={ScintillationTextImg} bgImageUrl={AudienceImg} />
      <AboutUs displayRegisterNow={true} />
      <Session/>
      <Competitions/>
      <Workshop/>
      {/* <Modal/> */}
      <ImageGrid images={images} />
        <div className="text-center mt-8">
          <RegisterNowButton><Link to="/signup">REGISTER NOW !</Link></RegisterNowButton>
        </div>
      <Footer/>
    </div>
  );
};

export default HomePage;