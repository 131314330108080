import React from "react";
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope, FaInstagram, FaFacebook, FaLinkedin, FaYoutube, FaMedium } from "react-icons/fa";

const Footer = () => {
  return (
    <footer id="footer" className="persian-indigo-bg lg:h-100 sm:h-120 mt-10 flex justify-center">
      <div className="container mx-auto py-4 text-center mb-[2rem]">
        <div className="flex items-center justify-around px-0 lg:flex-row sm:flex-col">
          <div className="flex flex-col lg:w-1/2 sm:3/4 text-center text-white pl-12 pt-12 lg:text-[1rem] sm:text-[2rem]">
            <p className="lg:mb-2 sm:mb-5">
              <FaMapMarkerAlt className="inline-block mr-2" />
              Entrepreneurship Development Cell, 1st Floor, Department of Human Resource Management, IIEST, Shibpur, Howrah, West Bengal 711103
            </p>
            <p className="lg:mb-2 sm:mb-5">
              <FaPhoneAlt className="inline-block mr-2" />
              +91 89278 54615
            </p>
            <p className="lg:mb-2 sm:mb-5">
              <FaEnvelope className="inline-block mr-2" />
              iiests.edc@gmail.com
            </p>
          </div>
          <div className="lg:w-1/2 sm:3/4 item-center pt-12">
            <a
              href="https://instagram.com/edc.iiests?igshid=ZmVmZTY5ZGE="
              target="_blank"
              rel="noopener noreferrer"
              className="text-white hover:text-gray-800 mr-4 lg:text-5xl sm:text-7xl"
            >
              <FaInstagram className="inline-block mr-2" />
            </a>
            <a
              href="https://www.facebook.com/edciiests/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white hover:text-gray-800 mr-4 lg:text-5xl sm:text-7xl"
            >
              <FaFacebook className="inline-block mr-2" />
            </a>
            <a
              href="https://www.linkedin.com/company/edciiests/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white hover:text-gray-800 mr-4 lg:text-5xl sm:text-7xl"
            >
              <FaLinkedin className="inline-block mr-2" />
            </a>
            <a
              href="https://youtube.com/@e-celliiestshibpur1530"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white hover:text-gray-800 mr-4 lg:text-5xl sm:text-7xl"
            >
              <FaYoutube className="inline-block mr-2" />
            </a>
            <a
              href="https://medium.com/@iiests.edc"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white hover:text-gray-800 lg:text-5xl sm:text-7xl"
            >
              <FaMedium className="inline-block mr-2" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
