import React, { useState, useEffect } from 'react';
import { Table, ImageRow, TintedImage } from '../styles';

const ImageGrid = ({ images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((currentImageIndex + 1) % images.length);
    }, 3000);

    return () => {
      clearInterval(intervalId);
    };
  }, [currentImageIndex, images.length]);

  return (
    <div className="flex justify-around items-center mt-10">
    <div className="flex flex-row items-center justify-around mt-20 mb-20 sm:w-80 lg:w-100%">
      <img src={images[currentImageIndex]} alt="Slideshow" />
      <img src={images[(currentImageIndex+1) % images.length]} alt="Slideshow" />
      <img src={images[(currentImageIndex+2) % images.length]} alt="Slideshow" />
    </div>
    </div>
  );
};

export default ImageGrid;