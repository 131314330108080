import React from "react";
import { Link } from "react-router-dom";
import { Link as LinkScroll } from "react-scroll";
import { HeroContainer, EventTitle, RegisterNowButton } from "../styles";
import Timer from "./Timer";
import bgvideo from "../assets/bgvideo.mp4";
import mouse from "../assets/mouse.png";
import "../styles/Hero.css";

const Hero = ({ eventName, bgImageUrl }) => {
  return (
    <div className="heroContainer">
      <div className="video-container">
        <video loop={true} muted={true} autoPlay={true}>
          <source src={bgvideo} type="video/mp4" />
        </video>
      </div>
      <HeroContainer containerHeight="800px" bgImageUrl={bgImageUrl}>
        <EventTitle>
          <img src={eventName} alt="Event logo" style={{ zIndex: "1000" }} />
          <div className="mt-4" style={{ zIndex: "1000" }}>
            यत् भावो – तत् भवति – You become what you believe.
          </div>
        </EventTitle>
        <Timer />
        <RegisterNowButton fontSize="18px">
          <Link to="/signup">REGISTER NOW !</Link>
        </RegisterNowButton>
        <LinkScroll to="about-us" smooth={true} duration={1000}>
          <img src={mouse} className="scroll-below" />
        </LinkScroll>
      </HeroContainer>
    </div>
  );
};

export default Hero;
