import React from 'react';
import '../styles/Modal.css';
import 'animate.css';
import { Link } from 'react-router-dom';

const Modal = ({modalTopic, modalDesc, setshowModal}) => {

  const closeModal = ()=>{
    setshowModal(false);
  }

  return (
    <div className='modal-component-container'>
        <div className="modal-container  animate__animated animate__fadeInDown">
            <div className="modal-topic">{modalTopic}</div>
            <div className="modal-desc">
                {modalDesc}
            </div>
            <div className="modal-buttons">
                <button className='modal-close-button' onClick={closeModal}>Close</button>
                <button className='modal-register-button'><Link to="/signup">Register Now</Link></button>
            </div>
        </div>
    </div>
  )
}

export default Modal
