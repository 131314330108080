let currTime = {};
let eventTime = {};

const adjustTime = (timeParam1, timeParam2, timeDifference) =>{
    if(timeParam1 < timeParam2) {
        return [timeDifference, -1];
    }
    return [0,0];
}

const calcSecondsLeft=()=>{
    const adjRes = adjustTime(eventTime.eventSeconds,currTime.currSeconds,60);
    eventTime.eventSeconds+=adjRes[0];
    eventTime.eventMinutes+=adjRes[1];
    let secondsLeft = eventTime.eventSeconds-currTime.currSeconds;
    return secondsLeft;
}

const calcMinutesLeft = ()=>{
    const adjRes = adjustTime(eventTime.eventMinutes,currTime.currMinutes,60);
    eventTime.eventMinutes+=adjRes[0];
    eventTime.eventHours+=adjRes[1];
    let minutesLeft = eventTime.eventMinutes-currTime.currMinutes;
    return minutesLeft;
}

const calcHoursLeft = ()=>{
    const adjRes = adjustTime(eventTime.eventHours,currTime.currHours,24);
    eventTime.eventHours+=adjRes[0];
    eventTime.eventDate+=adjRes[1];
    let hoursLeft = eventTime.eventHours-currTime.currHours;
    return hoursLeft;
}

const calcDaysLeft = () => {
    return eventTime.eventDate-currTime.currDate;
}

const calcTimeLeft=()=>{
    let date = new Date();
    currTime = {
        currDate:date.getDate(),
        currHours:date.getHours(),
        currMinutes:date.getMinutes(),
        currSeconds:date.getSeconds(),
    };
    eventTime = {
        eventDate:17,
        eventHours:10,
        eventMinutes:0,
        eventSeconds:0
    };

    const timeLeft = {};
    //proper sequence to be maintained from Smallest to Largest time parameter
    timeLeft.secondsLeft = Math.max(0,calcSecondsLeft());
    timeLeft.minutesLeft = Math.max(0,calcMinutesLeft());
    timeLeft.hoursLeft = Math.max(0,calcHoursLeft());
    timeLeft.daysLeft = Math.max(0,calcDaysLeft());
    return timeLeft;
}

export default calcTimeLeft;
